<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <permissions-list
          :permissions-groups="permissionsGroups"
          permissions-getter="hasClientPermission"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PermissionsList from '@/components/lists/PermissionsList.vue'

export default {
  name: 'ClientPermissions',
  components: {
    PermissionsList
  },
  data () {
    return {
      permissionsGroups: [
        {
          group: 'permissions.groups.client',
          permissions: [
            { value: 'change_client', text: 'permissions.edit.client' },
            { value: 'view_client', text: 'permissions.view.client' },
            { value: 'add_client', text: 'permissions.add.client' },
            { value: 'delete_client', text: 'permissions.delete.client' }
          ]
        },
        {
          group: 'permissions.groups.paymentMethod',
          permissions: [
            { value: 'view_clients_paymentmethod', text: 'permissions.view.paymentMethod' },
            { value: 'add_clients_paymentmethod', text: 'permissions.add.paymentMethod' },
            { value: 'delete_clients_paymentmethod', text: 'permissions.edit.paymentMethod' }
          ]
        },
        {
          group: 'permissions.groups.subscription',
          permissions: [
            { value: 'change_clients_subscription', text: 'permissions.edit.subscription' },
            { value: 'view_clients_subscription', text: 'permissions.view.subscription' },
            { value: 'add_clients_subscriptionschedule', text: 'permissions.add.subscriptionSchedule' },
            { value: 'delete_clients_subscriptionschedule', text: 'permissions.delete.subscriptionSchedule' },
            { value: 'view_clients_subscriptionschedule', text: 'permissions.view.subscriptionSchedule' }
          ]
        }
      ]
    }
  }
}
</script>
